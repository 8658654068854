/** @define abouthp;  weak */
.abouthp {
  padding-top: 50px;
  padding-bottom: 50px;
  background: url("/images/content/about_bcg.webp") no-repeat bottom center;
}
@media only screen and (min-width: 1200px) {
  .abouthp {
    padding-top: 140px;
  }
}
@media only screen and (min-width: 900px) {
  .abouthp {
    background-position: center right 120px;
  }
}
@media only screen and (min-width: 1200px) {
  .abouthp {
    background-position: bottom right 120px;
  }
}
.abouthp h1,
.abouthp p {
  margin-bottom: 15px;
}
.abouthp__text {
  width: 100%;
  margin-bottom: 30px;
}
@media only screen and (min-width: 900px) {
  .abouthp__text {
    flex: 1;
    width: auto;
    margin-bottom: 0;
  }
}
.abouthp__year {
  width: 100%;
  padding: 100px 0;
}
@media only screen and (min-width: 900px) {
  .abouthp__year {
    width: 380px;
    padding: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .abouthp__year {
    width: 480px;
  }
}
.abouthp__year .year {
  position: relative;
  margin-bottom: -20px;
  padding-right: 80px;
  color: #f7a810;
  font-size: 150px;
  line-height: 1;
  text-align: right;
}
.abouthp__year .year span {
  position: absolute;
  top: 10px;
  font-size: 53px;
}
.abouthp__year .slogan {
  text-align: right;
}
.abouthp__year .slogan span {
  display: inline-block;
  max-width: 250px;
}
/*# sourceMappingURL=css/abouthp.css.map */